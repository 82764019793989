import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { navigate } from 'gatsby';
import axios from 'axios';

import { Image, Text, Box, Button } from 'rebass';
import { Label, Input } from '@rebass/forms';
import Layout from '../components/Layout';
import * as PropTypes from '../lib/proptypes';

import registerBG from '../images/register-bg.jpg';
import nanodots from '../images/nanodots-seal-1.png';
import { COLOR } from '../lib/styles';
import { NANO_ENDPOINT } from '../lib/config';
import { $sendEmail } from '../components/Shared/state';

const withStore = connect((state) => ({
  post: state.Shared.selectedPost,
}));

const container = {
  backgroundImage: `url('${registerBG}')`,
  width: '100%',
  height: '100vh',
  backgroundSize: 'cover',
  textAlign: 'center',
  justifyContent: 'center',
};

const overlay = {
  backgroundColor: 'rgba(0,0,0,0.5)',
  zIndex: 99999,
  width: '100%',
  height: '100%',
  top: '0',
  left: '0',
  position: 'fixed',
  bottom: '0',
  right: '0',
  padding: '10% 20%',
};
const modal = {
  backgroundColor: 'white',
  padding: '24px',
  '@media screen and (max-width: 1024px)': {},
};

const nanodotsImage = {
  width: '20vh',
  height: '20vh',
};

const Wrapper = (C) => withStore(C);

const Register = ({ dispatch }) => {
  /* -------------------------------------------------------------------------- */
  /*                                    State                                   */
  /* -------------------------------------------------------------------------- */
  const [formData, setFormData] = useState({
    code: '',
    id: '',
    email: '',
  });
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [validEmail, setValidEmail] = useState(true);
  const [redirectReady, setRedirectReady] = useState(false);

  /* -------------------------------------------------------------------------- */
  /*                                   Effects                                  */
  /* -------------------------------------------------------------------------- */

  // Makes sure gatsby build goes through since navigate
  // isn't available in the node runtime
  useEffect(() => {
    setRedirectReady(true);
  }, []);

  /* -------------------------------------------------------------------------- */
  /*                               Local functions                              */
  /* -------------------------------------------------------------------------- */

  function isValidEmail() {
    const regex = new RegExp(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/);
    return regex.test(formData.email) && formData !== 'email@example.com';
  }

  function isRegistrationFormEmpty() {
    return formData.code === '' || formData.id === '';
  }

  const register = async (e) => {
    e.preventDefault();
    if (!isRegistrationFormEmpty()) {
      const encryptedId = window.btoa(formData.id);
      const encryptedSecret = window.btoa(formData.code);
      const response = await axios.post(`${NANO_ENDPOINT}${encryptedSecret}/${encryptedId}`);
      if (response.data.success) {
        setSuccess(true);
        setError(false);
      } else {
        setError(true);
        setSuccess(false);
      }
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                                  Rendering                                 */
  /* -------------------------------------------------------------------------- */

  // Required in order to be able to redirect client-side
  if (!redirectReady) {
    return null;
  }

  return (
    <Layout padder>
      <h1>Register Product</h1>
      <div style={container}>
        {success && (
          <Box style={overlay}>
            <Box style={modal}>
              <Text fontSize={[4, 5, 6]} color={COLOR.primary} py={30} fontFamily="Lato">
                Congrats your product is authentic!
              </Text>
              <Text fontSize={[1, 2, 3]} color={COLOR.primary} py={30} fontFamily="Lato">
                Would you like to associate this product with an email address for promotions, discounts and exclusive
                offers in the future?
              </Text>
              <Input
                id="email"
                name="email"
                type="email"
                placeholder="email@example.com"
                value={formData.email}
                color="black"
                height="5vh"
                my={3}
                backgroundColor="white"
                onChange={(e) => setFormData({ ...formData, email: e.target.value })}
              />
              <Button
                style={{ cursor: 'pointer' }}
                width="100%"
                my={3}
                backgroundColor={isValidEmail() ? '#227bdf' : '#ffccc7'}
                variant="primary"
                height="5vh"
                type="submit"
                onClick={() => {
                  if (isValidEmail()) {
                    dispatch($sendEmail(formData.email))
                      .then(() => {
                        setSuccess(!success);
                      })
                      .catch(() => {
                        setSuccess(!success);
                        setValidEmail(!validEmail);
                      })
                      .finally(() => {
                        setFormData({
                          ...formData,
                          id: '',
                          code: '',
                          email: '',
                        });
                        navigate('/');
                      });
                  }
                }}
                disabled={!isValidEmail()}
              >
                {isValidEmail() ? 'Enter submit email' : 'Please enter a valid email'}
              </Button>
              <Button
                onClick={() => setSuccess(!success)}
                style={{ cursor: 'pointer' }}
                width="100%"
                my={3}
                variant="default"
                type="cancel"
                height="5vh"
                backgroundColor="#E6E6E6"
                color={COLOR.primary}
              >
                No, thanks
              </Button>
            </Box>
          </Box>
        )}
        {error && (
          <Box p={3} backgroundColor="#f2dede" color="red">
            The Product ID or Product Secret you have entered may be invalid
          </Box>
        )}
        <Text fontSize={[4, 5, 6]} p={3} fontFamily="Lato" color={COLOR.primary}>
          Register Product
        </Text>
        <Image src={nanodots} style={nanodotsImage} />
        <Box as="form" px={[4, 5, 6]} marginLeft="26%" width={1 / 2} alignSelf="center" onSubmit={register}>
          <Box>
            <Label py={3} color="black" htmlFor="id">
              *ID#
            </Label>
            <Input
              id="id"
              name="id"
              type="text"
              placeholder="ID#"
              value={formData.id}
              color="black"
              backgroundColor="white"
              onChange={(e) => setFormData({ ...formData, id: e.target.value })}
            />
          </Box>
          <Box>
            <Label color="black" py={3} htmlFor="code">
              *Code
            </Label>
            <Input
              id="code"
              name="code"
              type="code"
              color="black"
              placeholder="code"
              value={formData.code}
              backgroundColor="white"
              onChange={(e) => setFormData({ ...formData, code: e.target.value })}
            />
          </Box>
          <Button
            style={!isRegistrationFormEmpty() ? { cursor: 'pointer' } : { cursor: 'default' }}
            width="100%"
            my={3}
            backgroundColor={!isRegistrationFormEmpty() ? '#227bdf' : '#bfbfbf'}
            variant="primary"
            type="submit"
            disabled={isRegistrationFormEmpty()}
          >
            Register
          </Button>
        </Box>
      </div>
    </Layout>
  );
};

Register.propTypes = {
  ...PropTypes.withState,
};

export default Wrapper(Register);
